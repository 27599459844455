<template>
<section class="section-info">

  </section>
<span align="center"><h6>https://unsplash.com/photos/6bmOMqgfZwU</h6></span>
  <section class="section-three">
    <h3>Soziales</h3>
    <br/>
    Durch die aktuellen Krisen (Corona, Klima und Ukraine-Krieg) hat sich das Leben für viele Mitmenschen
    sehr stark geändert. Die aus den Krisen entstandene Inflation (und sehr hohen Energiekosten) machen es für viele Mitmenschen
    schwierig bis unmöglich. Es ist die Zeit in der wir als Gesellschaft auch zeigen können was wir wirklich können. Hier sind ein
    paar Links zu Seiten und Organisationen, die Menschen in Not helfen. Bitte daran denken, ein jeder Mensch freut sich über eine ehrliche helfende Hand.
    <br/>
    <b>München (Soziales)</b>
    <ul>
    <li><a href="https://muenchner-tafel.de/">Tafel München </a></li>
    <li><a href="https://www.condrobs.de/">Condrobs - Suchthilfe </a></li>
    <li><a href="https://ffmuc.net/">Freifunk München </a></li>
    <li><a href="https://www.heimatstern.org/">Heimatstern e.V.</a></li>

    </ul>
    <br/><br/><br/><br/><br/>
    <b>München (Nachhaltig)</b>
    <ul>
    <li><a href="https://computertruhe.de/tag/muenchen">Computer Truhe München </a></li>
    <li><a href="https://www.awm-muenchen.de/vermeiden/reparieren-statt-wegwerfen/repair-cafes">Repair Cafés München </a></li>
    <li><a href="https://munichmakerlab.de/">Munich Maker Lab (MuMaLab) </a></li>
    <li><a href="https://ffmuc.net/">Freifunk München </a></li>
    <li><a href="https://www.greencity.de/">Green City München </a></li>
    <li><a href="https://www.fablab-muenchen.de/">FabLab München </a></li>

    </ul>
    <br/><br/><br/><br/><br/>
    <b>Regensburg</b>
    <ul>
    <li><a href="https://computerspende-regensburg.de/">Computer Spende Regensburg </a></li>
    <li><a href="https://tafel-regensburg.de/">Tafel Regensburg </a></li>
    <li><a href="https://www.drugstop.de/">Drugstop - Suchthilfe </a></li>
    </ul>
<br/><br/><br/><br/><br/>
    <b>Lohr / Marktheidenfeld</b>
    <ul>
    <li><a href="https://lohr-a-main-marktheidenfeld.lions.de/">Lions - Lohr am Main / Marktheidenfeld</a></li>
    </ul>
  </section>


</template>

<script>

export default {
  name: 'social',
  components: {


  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
 data (){
   return {
     cssProps: {
     backgroundImage: `url(${require('@/assets/img/bg.jpg')}) no-repeat`
     }
   }
 }
}
</script>
