<template>
<section class="section-notfound">
    <h1></h1>
  </section>
<span align="center"><h6>Bildquelle: https://unsplash.com/photos/52jRtc2S_VE</h6></span>
  <section class="section-three">
    <h3>404</h3>

    <p>
      Die gesuchte Seite befindet sich nicht an diesem Ort.
    </p>
  </section>


</template>

<script>

export default {
  name: 'notFound',
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 }
}
</script>
