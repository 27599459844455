<template>
<section class="section-about">
    <h1></h1>
  </section>
<span align="center"><h6>Bildquelle: https://unsplash.com/photos/5Ne6mMQtIdo</h6></span>
  <section class="section-three">
    <h3>Über diese Seite</h3>

    <p>
    Diese Seite ist als rein privat anzusehen. Es werden keine Cookies oder dergleichen gespeichert.
    </p>
  </section>


</template>

<script>

export default {
  name: 'about',
  components: {
  //  HelloWorld,


  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
 data (){
   return {
     cssProps: {
     backgroundImage: `url(${require('@/assets/img/bg.jpg')}) no-repeat`
     }
   }
 }
}
</script>
