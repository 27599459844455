<template>
<section class="section-impressum">
    <h1> </h1>
  </section>
<p>
<span align="center"><h6>Bildquelle: https://unsplash.com/photos/TamMbr4okv4</h6></span>
</p>
  <section class="section-three">
    <h2>Impressum</h2>
    <h3>Pflichtangaben nach §5 Telemediengesetz</h3>
    <p>

    <br/>
    Name: Peter Weinzierl, M.A.<br/>
    Adresse: @wp:bildung.social bzw. https://bildung.social/@wp<br>
    E-Mail: web@weinzierl-it.de<br />
    </p>

    <h3>Urheberrechtliche Hinweise</h3>
    <p>
    Sofern nicht explizit anders vermerkt, wurden alle Fotografien und Abbildungen auf dieser Webseite selbst erstellt.
    </p>


    <h3>Haftungsausschluss</h3>
    <p>
    Trotz sorgfältiger Kontrolle kann der Betreiber keine Verantwortung für die Inhalte der zitierten Links auf andere Websites übernehmen. Hierfür sind ausschliesslich deren Betreiber selbst verantwortlich.
    </p>
  </section>


</template>

<script>

export default {
  name: 'impressum',
  components: {


  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
 data (){
   return {
     cssProps: {
     backgroundImage: `url(${require('@/assets/img/passion.jpg')}) no-repeat`
     }
   }
 }
}
</script>
