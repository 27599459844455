<template>
<section class="section-info">

  </section>
<span align="center"><h6>https://unsplash.com/photos/6bmOMqgfZwU</h6></span>
  <section class="section-three">
    <h3>Über mich</h3>
    <br/>
    <ul>
    <li>geboren in den 80ern</li>
    <li>Linux-User seit 1997</li>

    <li>Nerd</li>
    <li>Datenschutz ist sehr wichtig (daher gibt es hier auch keine Cookies!)</li>
    <li>Studium der Fächer Englisch / Geschichte / Sozialkunde / Informatik (ja ich kenne Haskell und finde es gar nicht mal furchtbar)</li>
    <li>Sohn eines Informatik-Lehrers</li>
    <li>liebt es in der Freizeit zu Rudern und zu Programmieren</li>
    <li>arbeitet an OpenSource-Projekten mit</li>
    <li>Klassik, Jazz und Hard Rock-Fan (da gibt es mehr Gemeinsamkeiten als man denkt!)</li>
    <li>erreichbar über OpenSource Projekte wie Matrix oder Mastodon</li>
    <li>liebt Reisen (England, Frankreich, Schweiz und Skandi)</li>
    <li>Interesse an KI</li>
    <li>Interesse an HAM-Radio (Lizenz Klasse B seit 1998)</li>
    </ul>
  </section>


</template>

<script>

export default {
  name: 'info',
  components: {


  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
 data (){
   return {
     cssProps: {
     backgroundImage: `url(${require('@/assets/img/bg.jpg')}) no-repeat`
     }
   }
 }
}
</script>
