<template>
<section class="section-visavid">
    <h1>Visavid</h1>
  </section>
<center><h6>Bildquelle: https://unsplash.com/photos/qnWPjzewewA</h6></center>
  <section class="section-three">
    <h3>Material und Informationen zu Visavid</h3>

    <p>
    Hier finden sich Infos und Material zu Visavid.<br>
    <a :href="`${publicPath}Pres/Visavid.pdf`">Visavid - Präsentation</a>
    </p>
  </section>


</template>

<script>

export default {
  name: 'visavid',
  components: {
  },
  data () {

    return {
      publicPath: process.env.BASE_URL
      }
  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 }
}
</script>
