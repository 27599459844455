<template>
<section class="section-projects">

<h1></h1>
</section>
<span align="center"><h6>Bildquelle: https://unsplash.com/photos/5fNmWej4tAA</h6></span>


  <section class="section-three">
    <h3>Projekte</h3>
    <br>
    <ul>
    <li>Lerntools.org</li>
    <li>Administration mehrerer Nextcloud-Instanzen pro bono (für den öffentlichen (Bildungs-)Bereich)</li>
    <li>Administration mehrerer Jitsi-Instanzen pro bono (für den öffentlichen (Bildungs-)Bereich)</li>
    <li>App-Entwicklung (pro Bono)</li>
    <li>sehr einfache CLI-Anwendung zum Anzeigen von Vertretungen des DSB</li>
    <li>Basteln an Elektronik-Inhalten um den Fachbereich MINT zu stärken</li>
    
    </ul>


  </section>


</template>

<script>

export default {
  name: 'projects',
  components: {



  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
 data (){
   return {
     cssProps: {
     backgroundImage: `url(${require('@/assets/img/bg.jpg')}) no-repeat`
     }
   }
 }
}
</script>
