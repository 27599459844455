<template>
<section class="section-contact">
    <h1> </h1>
  </section>

<span align="center"><h6>Bildquelle: https://unsplash.com/photos/5Ne6mMQtIdo</h6></span>
  <section class="section-three">
    <h3>Kontakt</h3>

      Sie können mich gerne unter:<br>
      @wp:bildung.social bzw. https://bildung.social/@wp<br>
      erreichen.


  </section>


</template>

<script>

export default {
  name: 'contact',
  components: {
  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
 data (){
   return {
     cssProps: {
     backgroundImage: `url(${require('@/assets/img/contact.jpg')}) no-repeat`
     }
   }
 }
}
</script>
