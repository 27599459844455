<template>
<section class="section-gdpr">

  </section>
<span align="center"><h6>https://unsplash.com/photos/ABAmxzlot8E</h6></span>
  <section class="section-three">
    <h3>Datenschutzerklärung</h3>
    <p>
    Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist:<br/>
    <br/>
    Peter Weinzierl, M.A.<br/>
    @wp:bildung.social bzw. https://bildung.social/@wp<br/>

    </p>
    <h3>Betroffenenrechte</h3>
    <p>
    Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:<br/>
      <ul>
      <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung (Art. 15 DSGVO)</li>
      <li>Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO)</li>
      <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO)</li>
      <li>Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO)</li>
      <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO) und</li>
      <li>Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).</li>

      </ul>
      <br>
      Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen. Die Rechtmäßigkeit der bereits erteilten Datenverarbeitungsvorgänge bleibt vom erteilten Widerruf unberührt.

      Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige Behörde.<br>
      <br>
      <ul>
      <li>Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:
      https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</li>
      <li>Die Kontaktdaten der für uns zuständigen Datenschutzaufsichtsbehörde lauten:
      Bayerisches Landesamt für Datenschutzaufsicht (BayLDA), Promenade 27, 91522 Ansbach.</li>
      </ul>
      <br />
      </p>
      <h3>Erfassung allgemeiner Informationen beim Besuch unserer Website</h3>
      <p>
      <b>Art und Zweck der Verarbeitung:</b><br>
      Wenn Sie auf unsere Website zugreifen, d.h., wenn Sie sich nicht registrieren oder anderweitig Informationen übermitteln, werden automatisch Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers, Ihre IP-Adresse und ähnliches.

      Sie werden insbesondere zu folgenden Zwecken verarbeitet:<br/><br>
      <ul>
      <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
      <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
      <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
      <li>zur Optimierung unserer Website.</li>
      </ul>
      <br>
      Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Informationen dieser Art werden von uns ggfs. anonymisiert statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.<br>
      <br>
      <b>Rechtsgrundlage und berechtigtes Interesse:</b><br>
      Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.
      <br><br>
      <b>Empfänger:</b><br>
      Empfänger der Daten sind ggf. technische Dienstleister, die für den Betrieb und die Wartung unserer Webseite als Auftragsverarbeiter tätig werden.
      <br><br>
      <b>Drittlandtransfer:</b> <br>
      Die erhobenen Daten werden nicht in Drittländer übertragen.<br>
      <br>
      Folgende Datenschutzgarantien liegen vor:<br>
      <br>
      <b>Speicherdauer:</b><br>
      Die Daten werden gelöscht, sobald diese für den Zweck der Erhebung nicht mehr erforderlich sind. Dies ist für die Daten, die der Bereitstellung der Website dienen, grundsätzlich der Fall, wenn die jeweilige Sitzung beendet ist.

      Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens 14 Tagen der Fall. Eine darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
      <br><br>
      <b>Bereitstellung vorgeschrieben oder erforderlich:</b><br>
      Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist jedoch der Dienst und die Funktionsfähigkeit unserer Website nicht gewährleistet. Zudem können einzelne Dienste und Services nicht verfügbar oder eingeschränkt sein. Aus diesem Grund ist ein Widerspruch ausgeschlossen.
      <br><br>
      <b>SSL-Verschlüsselung</b><br>
      Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.<br><br>
      </p>
      <h3>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h3>
      <p>
      Einzelfallbezogenes Widerspruchsrecht
      Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.

      Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.<br><br>

      Empfänger eines Widerspruchs<br>
      Peter Weinzierl<br>
      web@weinzierl-it.de<br>
      <br>
      <b>Änderung unserer Datenschutzbestimmungen</b><br>
      Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
      <br><br>
      <b>Fragen an den Datenschutzbeauftragten</b><br>
      Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation.
        </p>
  </section>


</template>

<script>

export default {
  name: 'gdpr',
  components: {


  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
 data (){
   return {
     cssProps: {
     backgroundImage: `url(${require('@/assets/img/bg.jpg')}) no-repeat`
     }
   }
 }
}
</script>
