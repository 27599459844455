<template>
<section class="section-download">
    <h1></h1>
  </section>
<span align="center"><h6>Bildquelle: https://unsplash.com/photos/snNHKZ-mGfE</h6></span>
  <section class="section-three">
    <h3>Downloadbereich</h3>

    <p>
    <ul>
      <li v-for="name in files" v-bind:key="name" style="list-style:none">
        <a :href="`${publicPath}.${name.location}`">{{name.name}}</a>
        </li>
      </ul>
    </p>
  </section>


</template>

<script>

import filesData from "../../public/test.json";
export default {
  name: 'download',
  components: {



  },
  mounted() {
   let externalScript = document.createElement('script')
   externalScript.setAttribute('src', '/header.js')
   document.head.appendChild(externalScript)
 },
 data (){
   return {
     cssProps: {
     backgroundImage: `url(${require('@/assets/img/bg.jpg')}) no-repeat`
     },
     files: filesData,
     publicPath: process.env.BASE_URL
   }
 }
}
</script>
